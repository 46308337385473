import { IntervalTimer, SimpleTimer, initSimpleTimerByText, initIntervalTimerByText } from '@tilda/common/Timer'

if (!window.acIntervalTimer) {
  window.acIntervalTimer = IntervalTimer
}

if (!window.acSimpleTimer) {
  window.acSimpleTimer = SimpleTimer
}

if (!window.acSimpleTimerByText) {
  window.acSimpleTimerByText = initSimpleTimerByText
}

if (!window.acIntervalTimerByText) {
  window.acIntervalTimerByText = initIntervalTimerByText
}
