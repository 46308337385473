export interface TimerPayload extends Record<string, string | undefined> {
  text: string;
  name?: string;
  year?: string;
  month?: string;
  day?: string;
  hour?: string;
  minute?: string;
  second?: string;
  utc?: string;
  interval?: string;
}

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

abstract class Timer {
  private nodeElement: HTMLDivElement
  protected storeName: string
  protected utc: number | null = null
  private readonly restart: boolean = true

  protected constructor ({ nodeElement, name, utc, restart, ...params }: { nodeElement: HTMLDivElement; name?: string; utc?: string; restart?: string; }) {
    this.nodeElement = nodeElement
    const prefixName = name || 'anyclass_timer'
    this.storeName = `${prefixName}_${window.location.pathname}_${utc}_${JSON.stringify(params)}`

    if (Number(utc)) {
      this.utc = Number(utc)
    }

    if (restart) {
      this.restart = restart === 'true' || restart === '1'
    }
  }

  private render (str: string): void {
    this.nodeElement.innerHTML = str
  }

  private static numpad (number: number): string {
    const l = number.toString().length > 1 ? number.toString().length : 2
    const n = `00${number}`
    return n.substr(-l)
  }

  protected getDate (): Date {
    if (typeof this.utc === 'number') {
      const date = new Date()
      return new Date(new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()).getTime() + this.utc * HOUR)
    }

    return new Date()
  }

  startTimer () {
    const x = setInterval(() => {
      const now = this.getDate().getTime()
      const distance = this.getCountDownDate() - now
      let days = Math.floor(distance / DAY)
      let hours = Math.floor((distance % DAY) / HOUR)
      let minutes = Math.floor((distance % HOUR) / MINUTE)
      let seconds = Math.floor((distance % MINUTE) / SECOND)
      if (distance < 0) {
        clearInterval(x)
        days = hours = minutes = seconds = 0
        localStorage.removeItem(this.storeName)
        if (this.restart) {
          this.startTimer()
        }
      }

      const timerText = `${Timer.numpad(days * 24 + hours)}:${Timer.numpad(minutes)}:${Timer.numpad(seconds)}`

      this.render(timerText)
    }, 1000)
  }

  abstract getCountDownDate (): number
}

export class SimpleTimer extends Timer {
  private readonly year: string
  private readonly month: string
  private readonly day: string
  private readonly hour: string
  private readonly minute: string
  private readonly second: string

  constructor (options: {
    nodeElement: HTMLDivElement;
    name?: string;
    year?: string;
    month?: string;
    day?: string;
    hour?: string;
    minute?: string;
    second?: string;
    utc?: string;
  }) {
    super(options)

    const date = this.getDate()
    this.year = options.year || String(date.getFullYear())
    this.month = options.month || String(date.getMonth() + 1)
    this.day = options.day || String(date.getDate())
    this.hour = options.hour || '0'
    this.minute = options.minute || '0'
    this.second = options.second || '0'

    this.startTimer()
  }

  isPlus (n: string): boolean {
    return /\+\d+/.test(n)
  }

  getFinishTime (): Date {
    const year = this.isPlus(this.year) ? this.getDate().getFullYear() + Number(this.year) : Number(this.year)
    const month = this.isPlus(this.month) ? this.getDate().getMonth() + Number(this.month) : Number(this.month) - 1
    const day = this.isPlus(this.day) ? this.getDate().getDate() + Number(this.day) : Number(this.day)
    const hour = this.isPlus(this.hour) ? this.getDate().getHours() + Number(this.hour) : Number(this.hour)
    const minute = this.isPlus(this.minute) ? this.getDate().getMinutes() + Number(this.minute) : Number(this.minute)
    const second = this.isPlus(this.second) ? this.getDate().getSeconds() + Number(this.second) : Number(this.second)

    return new Date(year, month, day, hour, minute, second)
  }

  getCountDownDate (): number {
    let countDownDate = Number(localStorage.getItem(this.storeName))
    if (!countDownDate) {
      countDownDate = this.getFinishTime().getTime()
      localStorage.setItem(this.storeName, countDownDate.toString())
    }
    return countDownDate
  }
}

export class IntervalTimer extends Timer {
  private readonly interval: number[] = []

  constructor (options: { nodeElement: HTMLDivElement; interval: string; }) {
    super(options)

    if (!options.interval) {
      console.log(options.nodeElement)
      console.error('[IntervalTimer] Параметр interval обязателен для заполнения.')
    }

    this.interval = options.interval
      .split(',')
      .map(x => Number(x))
      .sort((a, b) => a - b)

    this.startTimer()
  }

  getCountDownDate (): number {
    const countDownDate = this.getDate()
    countDownDate.setMinutes(0)
    countDownDate.setSeconds(0)

    this.interval.forEach((i) => {
      if (countDownDate.getTime() < new Date().getTime()) {
        countDownDate.setHours(i)
      }
    })

    if (countDownDate.getTime() < new Date().getTime()) {
      countDownDate.setHours(this.interval[0])
      countDownDate.setDate(countDownDate.getDate() + 1)
    }

    return countDownDate.getTime()
  }
}

export function initSimpleTimerByText (payload: TimerPayload): void {
  const text = payload.text || '{timer}'
  const divNodes = Array.from(document.querySelectorAll('div'))
  const spanNodes = Array.from(document.querySelectorAll('span'))
  const nodes = [...divNodes, ...spanNodes]
  Array.prototype.forEach.call(nodes, (spanNode) => {
    if (spanNode) {
      Array.prototype.forEach.call(spanNode.childNodes, function (node) {
        if (node.nodeType === 3 && node.nodeValue.indexOf(text) !== -1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-new
          new SimpleTimer({
            nodeElement: node.parentElement,
            ...payload
          })
        }
      })
    }
  })
}
export function initIntervalTimerByText (payload: TimerPayload): void {
  const text = payload.text || '{timer}'
  const divNodes = Array.from(document.querySelectorAll('div'))
  const spanNodes = Array.from(document.querySelectorAll('span'))
  const nodes = [...divNodes, ...spanNodes]
  Array.prototype.forEach.call(nodes, (spanNode) => {
    if (spanNode) {
      Array.prototype.forEach.call(spanNode.childNodes, function (node) {
        if (node.nodeType === 3 && node.nodeValue.indexOf(text) !== -1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-new
          new IntervalTimer({
            nodeElement: node.parentElement,
            ...payload
          })
        }
      })
    }
  })
}
